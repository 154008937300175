<script>
export default {
  name: "DomoticComponent",
  data() {
    return {
      
    };
  },

  methods: {}
};
</script>

<template>
    <section class="bg-slate-100 py-4" id="section7">
      <div class="max-w-6xl px-8 mx-auto pb-5">
        <h2 data-aos="fade-up" class="relative text-center text-2xl font-bold pb-2">¿Que otras cosas te enseñare?</h2>
        <h2 data-aos="fade-up" class="relative text-center text-xl pb-2">
          1. Estructura de corazones del espacio que es una comunidad para abordar consultas e inquietud que tengas de tu espacio .
        </h2>
        <h2 data-aos="fade-up" class="relative text-center text-xl pb-2">
          2. Como crear tu espacio a base de energía con la herramienta y filosofía milenaria del FengShui tradicional.
        </h2>
        <h2 data-aos="fade-up" class="relative text-center text-xl pb-2">
          3. Incluye la Domótica, espacio inteligente, a tu vivienda
        </h2>
      </div>
    </section>
</template>
  