<template>
  <section class="bg-slate-100 text-white py-4" id="home">
    <div class="max-w-6xl px-8 mx-auto mt-56">
      <div data-aos="fade-left" data-aos-duration="1500" class="flex flex-wrap items-center justify-between">
        <div class="w-full md:w-2/5 text-center mx-auto">
          <div>
            <h3 class="font-bold text-red-600 text-4xl">CREA TU ESPACIO INTERIOR.</h3>
            <div class="mt-4">
              <div class="text-black">
                <p class="my-3">Te ensañaré con mi método único 3 de 3 <br> <span class="font-bold">PLAN, MISIÓN, VISIÓN</span></p>
                <p class="my-3">Perderás el miedo al NO LO PUEDO HACER, ES LUJO.</p>
                <p class="my-3">CONVERTIRTE en CORAZÓN DE TU ESPACIO y motivarás a tu núcleo familiar a potenciar aun más tu bienestar.</p>
              </div>
              <div>
                <a href="#section7" class="bg-red-600 hover:bg-red-500 text-white font-bold py-2 px-4 rounded">QUIERO MÁS INFORMACIÓN</a>
              </div>
            </div>
          </div>
        </div>
        <div class="w-full md:w-3/5 flex flex-col items-center">
          <img src="/sin_fondo.png" alt="profile image" class="object-cover rounded-lg imgClass md:h-80">
        </div>
      </div>
    </div>
  </section>
</template>


<script>
export default {
    name: "HomeComponent",
};
</script>

<style scoped>
@media (min-width: 768px) {
  .imgClass {
    position: absolute;
    top: -185px;
    height: 650px;
  }
}
</style>