<script>
// import Domotic from './Domotic.vue';
export default {
  // components: { Domotic },
  name: "FengShuiComponent",
  data() {
    return {
      
    };
  },

  methods: {}
};
</script>

<template>
<div></div>
    <!-- <section class="py-4" id="fengShui">
      <div class="max-w-6xl px-8 mx-auto">
        <h2 data-aos="fade-up" class="relative text-center text-4xl font-bold pb-2">PRÓLOGO</h2>
        <h2 data-aos="fade-up" class="relative text-center text-xl font-bold pb-5">Lain</h2>
        <div data-aos="fade-left" data-aos-duration="1500" class="flex flex-wrap items-center justify-between">
          <p>
          Todo es energía y la energía es magnética.
          Sí, si. Tú puedes transformar tu vida cambiando tu energía y esto se hace cambiando tu interior.
          Conocerte a ti mismo, cambiar tus pensamientos, tus emociones, la forma enq ue encaras los acontecimientos de la vida.
          Por algo el gran maestro metafñisico Jesús de nazaret dijo hace más de 2000 años: "buscad primero el reino de Dios y lo demás vendrá por añadidura".
          Nada ocurre por casualidad, sino por CAUSAlidad, por sinceridad, por PRINCIPIO de causa y efecto. Todo tiene un PROPÓSITO.
          Si tienes este libro en tus manos, significa que contiene algo importante para ti.
          Gracias Andrea por escribirlo y a ti querido lector, por leerlo.
          Te quiero mucho.
          Lain, autor de la saga LA VOZ DE TU ALMA.
        </p>
        </div>
      </div>
    </section> -->
</template>
  