<template>
  <nav class="bg-white fixed w-full z-20 top-0 align-middle">
    <div class="flex flex-wrap items-center justify-evenly mx-auto px-2 lg:py-1 md:px-2 md:py-0 sm:py-2">
      <img src="../assets/logo-pro.jpg" class="h-24" alt="logo">
      <div class="items-center justify-between hidden w-full md:flex md:w-auto md:order-1" id="navbar-sticky">
        <ul class="flex flex-col p-4 md:p-0 font-bold md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:sticky">
          <li>
            <a href="#home" class="block py-2 px-3 text-red hover:text-red-600" aria-current="page">Inicio</a>
          </li>
          <li>
            <a href="#section2" class="block py-2 px-3 text-red hover:text-red-600 md:p-0">Libros</a>
          </li>
          <li>
            <a href="#section4" class="block py-2 px-3 text-red hover:text-red-600 md:p-0">Preguntas frecuentes</a>
          </li>
          <li>
            <a href="#section5" class="block py-2 px-3 text-red hover:text-red-600 md:p-0">Andrea</a>
          </li>
          <!-- <li>
            <a href="#experience" class="block py-2 px-3 text-red hover:text-red-600 md:p-0">Mis servicios</a>
          </li>
          <li>
            <a href="#skills" class="block py-2 px-3 text-red hover:text-red-600 md:p-0">Clientes</a>
          </li>
          <li>
            <a href="#projects" class="block py-2 px-3 text-red hover:text-red-600 md:p-0">Projectos</a>
          </li>
          <li>
            <a href="#contact" class="block py-2 px-3 text-red hover:text-red-600 md:p-0">Contacto</a>
          </li> -->
        </ul>
      </div>
    </div>
  </nav>

</template>

<script>
export default {
  name: "NavBarComponent",
  mounted() {
  },
  data() {
    return {
      isActive: false,
    };
  },
  methods: {}

};
</script>

<style scoped>
.bg-color {
  background-color: #353535 !important;
}
</style>
