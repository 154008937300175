<template>
    <section class="bg-slate-100 py-4 mt-12" id="section2">
      <div class="max-w-6xl px-8 mx-auto">
        <h2 data-aos="fade-up" class="relative text-center text-4xl font-bold pb-5">INVENTA TU ESPACIO INTERIOR</h2>
        <div data-aos="fade-left" data-aos-duration="1500" class="flex flex-wrap items-center justify-between">
          <div class="w-full md:w-2/5 mb-8 md:mb-0">
            <img src="../assets/librobg.png" alt="profile image" class="h-100 w-full md:h-96 md:w-96 object-cover rounded-lg">
          </div>
          <div class="w-full md:w-3/5 text-center">
            <div class="text-2xl font-semibold mb-4">TU CASA, TU HOGAR, TU TEMPLO</div>
            <div>
              <p class="text-justify mt-4">
                La ABUNDANCIA en todo su esplendor y en cualquier aspecto de tu vida, llega cuando tu TEMPLO (TU PROPIO HOGAR) está listo para RECIBIRLE. Nadie recibe de afuera lo que no ha sido capaz de crear dentro de casa. Este es el GRAN SECRETO de mi propio éxito y ahora quiero que el mundo se entere.
              </p>
            </div>
            <div class="my-5 text-center">
                <a href="#section7" class="bg-red-600 hover:bg-red-500 text-white font-bold py-2 px-4 rounded">COMPRAR AHORA</a>
            </div>
          </div>
        </div>
      </div>
    </section>
</template>

<script>
export default {
    name: "BookComponent",
};
</script>
  