<template>
  <div class="bg-white py-4" id="contact">
    <div class="max-w-6xl mx-auto px-8">
      <h2 data-aos="fade-up" class="relative text-center text-4xl font-bold pb-5">
        Contáctame
      </h2>
      <div data-aos="fade-right" data-aos-duration="1500" class="flex flex-wrap justify-between">
        <div class="w-full md:w-1/2 mb-8 md:mb-0">
          <div class="text-xl font-semibold mb-4">
            Escríbeme
          </div>
          <div class="space-y-1">
            <div class="flex items-center h-16">
              <i class="fas fa-user text-red-600 text-2xl"></i>
              <div class="ml-6">
                <div class="text-gray-700">Andrea Thomé</div>
              </div>
            </div>
            <div class="flex items-center h-16">
              <i class="fas fa-map-marker-alt text-red-600 text-2xl"></i>
              <div class="ml-6">
                <div class="text-gray-700">Barcelona, España</div>
              </div>
            </div>
            <div class="flex items-center h-16">
              <i class="fas fa-envelope text-red-600 text-2xl"></i>
              <div class="ml-6">
                <div class="text-gray-700">andrea@andreathome.es</div>
              </div>
            </div>
          </div>
        </div>

        <div class="w-full md:w-1/2">
          <form @submit.prevent="sendData">
            <input type="hidden" name="_subject" value="Nuevo mensaje de contacto">
            <!-- Redirige automáticamente después de enviar -->
            <input type="hidden" name="_next" value="https://localhost:8080/"> <!-- Cambia la URL a la tuya -->
            <div class="flex space-x-4 mb-4">
              <input type="text" class="border border-gray-400 rounded-lg w-1/2 p-3" placeholder="Nombre" name="name" v-model="name" required />
              <input type="text" class="border border-gray-400 rounded-lg w-1/2 p-3" placeholder="Apellidos" name="surnames" v-model="surnames" required />
            </div>
            <div class="mb-4">
              <input type="email" class="border border-gray-400 rounded-lg w-full p-3" placeholder="Correo electrónico" name="email" v-model="email" required />
            </div>
            <div class="mb-4">
              <input type="text" class="border border-gray-400 rounded-lg w-full p-3" placeholder="Asunto" name="subject" v-model="subject" required />
            </div>
            <div class="mb-4">
              <textarea class="border border-gray-400 rounded-lg w-full p-3 resize-none" rows="5" placeholder="Mensaje..." name="message" v-model="body" required></textarea>
            </div>
            <div>
              <button type="submit" class="bg-red-600 hover:bg-red-500 text-white font-bold py-2 px-4 rounded-full flex items-center justify-center" :disabled="isLoading">
                <template v-if="isLoading">
                  <svg class="animate-spin h-5 w-5 text-white mr-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
                  </svg>
                  Enviando...
                </template>
                <span v-else>Enviar mensaje</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "ContactComponent",
  data() {
    return {
      name: '',
      surnames: '',
      email: '',
      subject: '',
      body: '',
      isLoading: false,
    };
  },
  methods: {
   /* sendEmail() {
      this.isLoading = true; // Inicia el estado de carga
      // Crear un formulario y enviarlo a FormSubmit
      const form = document.createElement('form');
      form.action = "https://api.ballthor.com/api/public/andrea-lead"//"https://formsubmit.co/andrea@andreathome.es"; // Cambia la URL a tu correo
      form.method = "POST";

      // Agregar campos ocultos con los datos del formulario
      form.innerHTML = `
        <input type="hidden" name="name" value="${this.name}">
        <input type="hidden" name="surnames" value="${this.surnames}">
        <input type="hidden" name="email" value="${this.email}">
        <input type="hidden" name="subject" value="${this.subject}">
        <input type="hidden" name="message" value="${this.body}">
        <input type="hidden" name="_next" value="Web Andrea Thome"> <!-- Cambia la URL a la tuya -->
      `;

      document.body.appendChild(form);
      form.submit(); // Enviar el formulario

      // Restablecer el estado de carga después de un pequeño retraso para dar tiempo a la redirección
      setTimeout(() => {
        this.isLoading = false;
      }, 1000);
    },*/
    sendData() {
      this.isLoading = true;
      // Objeto JSON que necesitas enviar
      const data = {
        contactName: this.name,
        contactLastName: this.surnames,
        contactEmail: this.email,
        subject: this.subject,
        message: this.body
      };

      // Realizar la solicitud POST
      axios
          .post('https://api.ballthor.com/api/public/andrea-lead', data)
          .then((response) => {
            console.log('Datos enviados con éxito:', response.data);
            this.isLoading = false; // Cambiar la variable a false cuando se complete
          })
          .catch((error) => {
            console.error('Error al enviar los datos:', error);
            this.isLoading = false; // Cambiar la variable a false incluso si hay un error
          });
    },
  },
};
</script>
