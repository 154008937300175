<template>
  <section class="bg-slate-100 py-10 flex items-center justify-center" id="section5">
    <div class="max-w-5xl mx-auto px-8">
      <h2 data-aos="fade-up" class="relative text-center text-4xl font-semibold mb-12 pb-6">
        ANDREA THOMÉ
      </h2>
      <div data-aos="fade-left" data-aos-duration="1500" class="flex flex-col lg:flex-row items-center justify-center gap-16">
        <div class="w-full lg:w-1/2 flex justify-center">
          <img src="/aboutme.jpg" alt="profile image" class="h-96 w-80 object-cover rounded-lg">
        </div>
        <div class="w-full lg:w-1/2 text-left lg:text-left">
          <h6 class="text-red-600 text-sm mb-2">SOBRE MI</h6>
          <p class="text-justify text-gray-700">
            Soy autora de mi propia vida, con más de 30 años de experiencia en transformar la vida de las personas a través de la organización y aromonización de sus entornos. Mi misión es crear ambientes que promuevan el bienestar y reflejen la identidad única de cada individuo.
            Mi pasión comenzó desde niña, organizaba mi dormitorio como si fuera mi casa. Tambien ayudaba a organizar los espacios de los negocios de mi barrio.
            A los 17 años fui a estudiar Buenos Aires, a estudiar Diseño de Interiores que realmente es mi pasión. En el año 1989, me mude a Uruguay donde comencé mi emprendimiento personal, "Artidea", mi empresa de interiores durante mas de 30 años. 
            Durante este periodo segui aprendiendo y formando de los mejores de este secotor en Italia, Inglaterra, Francia y España. Actualmente me encuentro en España donde continuo ayudando a las personas a potenciar su bienestar.
            
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "AboutMeComponent",
};
</script>
