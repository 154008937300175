<script>
export default {
  name: "DomoticComponent",
  data() {
    return {
      
    };
  },

  methods: {}
};
</script>

<template>
    <section class="py-4" id="section4">
      <div class="max-w-6xl px-8 mx-auto">
        <h2 data-aos="fade-up" class="relative text-center text-4xl font-bold pb-5">DOMINA TU ESPACIO INTERIOR</h2>
        <h2 data-aos="fade-up" class="relative text-center text-xl font-bold pb-2">Vuélvete Corazón del espacio</h2>
        <h2 data-aos="fade-up" class="relative text-center text-xl font-bold pb-5">
          Que tu energía vibre en sintonía manifestando tu
          pertenecía y permanezca en tu casa
        </h2>

        <div data-aos="fade-left" data-aos-duration="1500" class="flex flex-wrap items-center justify-between">
          <div class="max-w-5xl mx-auto px-4 text-center">
            <div class="grid gap-8 sm:grid-cols-2 lg:grid-cols-2">
              <!-- Card 1 -->
              <div class="flex items-start space-x-4">
                <div>
                  <h3 class="text-lg font-bold text-red-600 mb-2">1. ¿Tienes miedo de organizar tu espacio?</h3>
                  <p class="text-gray-700">
                    No te preocupes con mi plan garantizamos confianza a la hora de organizar tu
                    espacio.
                    Mi misión es transformarte para manifestar tus gustos con tu energía dentro del espacio.
                  </p>
                </div>
              </div>

              <!-- Card 2 -->
              <div class="flex items-start space-x-4">
                <div>
                  <h3 class="text-lg font-bold text-red-600 mb-2">2. ¿Te cuesta saber cuáles son las aéreas o zonas?</h3>
                  <p class="text-gray-700">
                    La mayoría de las personas terminan expresando y conectando con el espacio creando una
                    unión que refleje sus gustos y funciones
                    Todo el plan empleado te brindará la seguridad para potenciar tu bienestar
                  </p>
                </div>
              </div>

              <!-- Card 3 -->
              <div class="flex items-start space-x-4">
                <div>
                  <h3 class="text-lg font-bold text-red-600 mb-2">3. ¿No logras organizar tus aéreas?</h3>
                  <p class="text-gray-700">
                    Aquí aprenderás el arte de organizar tus aéreas
                    Descubrirás en crear potentes aéreas que te llevarán a prestar atención de forma genuina y
                    honesta hacia potenciar tu actividad en el día cotidiano.
                  </p>
                </div>
              </div>

              <!-- Card 4 -->
              <div class="flex items-start space-x-4">
                <div>
                  <h3 class="text-lg font-bold text-red-600 mb-2">4. ¿Quieres dar más energía a tus espacios y a tu familia?</h3>
                  <p class="text-gray-700">
                    Uno de los temas que te mostraré es todo lo que tienes que tomar en cuenta para crear tu
                    propia energía en tus estancias.
                    Verás desde la zonificación previa creando que la circulación no tenga obstáculos para que
                    tu energía fluya y refleje realmente tu presencia, emoción y resuene a tu familia.
                  </p>
                </div>
              </div>

              <!-- Card 5 -->
              <div class="flex items-start space-x-4">
                <div>
                  <h3 class="text-lg font-bold text-red-600 mb-2">5. ¿Quieres aprender a emocionar a tu espacio?</h3>
                  <p class="text-gray-700">
                    Te contaré mis secretos utilizados para potenciar tu bienestar.
                    Todas las personas pueden expresar sus emociones pero no todo el mundo sabe reflejar la
                    emoción a través de sus elementos de su espacio.
                    Te contaré mis secretos y el de mis clientes amigos que utilizaron para conectarse con el
                    espacio a través de la mente creativa creando una imagen que perdura en el tiempo
                  </p>
                </div>
              </div>
               <div class="flex items-start space-x-4">
                <div>
                  <h3 class="text-lg font-bold text-red-600 mb-2">6. ¿Quieres ser escuchado por tu entorno?</h3>
                  <p class="text-gray-700">
                    En este apasionado reto aprenderás cómo influir positivamente a tu entorno a el interior de
                    tu espacio.
                    Sabrás cómo crear un ambiente inspirador y motivador para lograr la permanencia. Y sentir
                    que dentro de casa estarás permanentemente generando un mejor bienestar.
                    Te lo enseñaré todo para que seas el alma de tu hogar o espacio laboral.
                    Convirtiéndote en una persona inolvidable..¡ Corazón del espacio. !
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
</template>
  