<template>
  <footer class="flex flex-col md:flex-row items-center justify-between bg-gray-800 px-5 py-3 text-white">
    <div>
      <span><span class="far fa-copyright"></span> Andrea Thomé | {{ currentYear }}</span>
    </div>
    
    <div class="flex md:mt-0 space-x-4">
      <a target="_blank" href="https://es.linkedin.com/in/andrea-thom%C3%A9-6aba383b" title="Go to linkedin profile" class="text-white hover:text-gray-300 transition-all">
        <i class="fa-brands fa-linkedin-in fa-xl"></i>
      </a>
      <a target="_blank" href="https://www.instagram.com/byandreathome/" title="Go to instagram profile" class="text-white  hover:text-gray-300 transition-all">
        <i class="fa-brands fa-instagram fa-xl"></i>
      </a>
      <span>andrea@andreathome.es</span>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterComponent",
  data() {
    return {
      currentYear: new Date().getFullYear()
    };
  }
};


</script>